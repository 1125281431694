import { CommonConstants } from 'src/app-core/constants/common-constants';

export class SettingsDataModel {

    public scheduleShipmentModel = {
        userId: null,
        accountSettingType: CommonConstants.accountSettingTypeConstants.scheduleShipment,
        settings: {
            DonotWarnOnCommodityOverride: false,
            RememberTheLastValuesSetForDocument: false,
            DontWarnOnPossibleMissingBOLOnlyData: false,
            BookingChoice: CommonConstants.bookingModeConstants.bookAndWait
        }
    };

    public settingsPrinterModel = {
        userId: null,
        accountSettingType: CommonConstants.accountSettingTypeConstants.printer,
        settings: {
            ShipmentLabelFormat: '1',
            LabelsPerPackage: null,
            UseShipmentLabels: null,
            AutoSelectPackageCount: null
        }
    };

    public settingsMapModel = {
        userId: null,
        accountSettingType: CommonConstants.accountSettingTypeConstants.map,
        settings: {
            InboundShipmentColor: '#000000',
            OutboundShipmentColor: '#F79646',
            DropShipmentColor: '#943734',
            ShuttleShipmentColor: '#EDA915',
            SelectedShipmentColor: '#0000FF'
        }
    };

    public defaultOwnerSettingModel = {
        userId: null,
        defaultOwnerId: null,
        defaultOwnerName: null,
        accountSettingType: CommonConstants.accountSettingTypeConstants.owner,
    };

    public settingsMenuList = [
        {
            routerLink: 'profile', title: 'Profile', isVisible: true,
            permissions: []
        },
        {
            routerLink: 'enterprise', title: 'Enterprise', isVisible: true,
            permissions: []
        },
        {
            routerLink: 'schedule-shipment', title: 'Schedule Shipment', isVisible: false,
            permissions: ['ScheduleShipmentModuleAccess', 'BOLOnlyModuleAccess']
        },
        {
            routerLink: 'shipment-tracking', title: 'Shipment Tracking', isVisible: false,
            permissions: ['ShipmentTrackingModuleAccess']
        },
        {
            routerLink: 'printer', title: 'Printer', isVisible: false,
            permissions: [
                'BOLOnlyModuleAccess',
                'ScheduleShipmentModuleAccess',
                'ShipmentTrackingModuleAccess',
                'VendorBooking'
            ]
        },
        {
            routerLink: 'map', title: 'Map', isVisible: false,
            permissions: ['ShipmentTrackingModuleAccess']
        },
        {
            routerLink: 'vendor-booking', title: 'Vendor Booking', isVisible: false,
            permissions: ['VendorBooking']
        },
        {
            routerLink: 'application-settings', title: 'Application Settings', isVisible: true,
            permissions: []
        },
    ];

    public printDropDownList = [
        { value: '1', display: 'Standard 8.5 x 11 inch' },
        { value: '2', display: '4 x 6 inch' }
    ];

    public displayModeDropDownList = [
        { value: '1', display: 'Map' },
        { value: '2', display: 'Grid' }
    ];

    public profileFormModel = {
        displayName: null,
        phone: null,
        startUpPage: null,
        email: null,
        timeZoneId: null,
        isAddressCollapse: false,
        vendorBookingStartUpPage: CommonConstants.vendorBookingModuleConstants.LTL,
        enableAddressLookup: true
    };

    public startUpDropDownListModel = [
        {
            value: '0',
            displayText: 'Select',
            isActive: true,
            isVisible: true
        },
        {
            value: '4',
            displayText: 'Tracking',
            isActive: false,
            isVisible: false
        },
        {
            value: '6',
            displayText: 'Quick Rate',
            isActive: false,
            isVisible: false
        },
        {
            value: '5',
            displayText: 'Schedule Shipment',
            isActive: false,
            isVisible: false
        },
        {
            value: '301',
            displayText: 'Vendor Booking',
            isActive: false,
            isVisible: false
        },
        {
            value: '304',
            displayText: 'Vendor Booking',
            isActive: false,
            isVisible: false
        },
        {
            value: '217',
            displayText: 'Business Intelligence',
            isActive: false,
            isVisible: false
        },
        {
            value: '201',
            displayText: 'Reports',
            isActive: false,
            isVisible: false
        },
        {
            value: '210',
            displayText: 'Bol Only',
            isActive: false,
            isVisible: false
        }
    ];

    public bookingModeDropDownList = [
        {
            value: CommonConstants.bookingModeConstants.bookAndWait,
            display: 'Book and Wait',
            isActive: false,
            permissions: ['ScheduleShipmentModuleAccess']
        },
        {
            value: CommonConstants.bookingModeConstants.notifyOnly,
            display: 'Notify Only',
            isActive: false,
            permissions: ['ShipmentTrackingModuleAccess']
        },
        {
            value: CommonConstants.bookingModeConstants.notifyAndEmail,
            display: 'Notify and Email',
            isActive: false,
            permissions: ['ShipmentTrackingModuleAccess']
        }
    ];

    public vendorBookingTypeListModel = [
        {
            value: 'LTL',
            displayText: 'LTL Vendor',
            isVisible: false
        },
        {
            value: 'TL',
            displayText: 'TL Vendor',
            isVisible: false
        }
    ];

}
